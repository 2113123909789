<i18n>
ru:
  dataConsent: Я даю согласие на
  dataConsentLink: обработку своих персональных данных.
  personalDataMessage: 'Предоставляя свои персональные данные, Вы соглашаетесь с '
  personalDataMessageOrder: 'Оформляя заказ, Вы соглашаетесь с '
  personalDataMessageLink: условиями обработки персональных данных
ua:
  dataConsent: Я даю згоду на
  dataConsentLink: обробку персональних даних.
  personalDataMessage: 'Надаючи свої персональні дані, ви погоджуєтеся з '
  personalDataMessageOrder: 'Оформляючи замовлення, Ви погоджуєтеся з '
  personalDataMessageLink: умовами обробки персональних даних
us:
  dataConsent: I agree to
  dataConsentLink: the terms of use.
  personalDataMessage: 'By submitting your personal data, you agree to '
  personalDataMessageOrder: 'By placing an order, you agree to '
  personalDataMessageLink: the terms of personal data processing
</i18n>

<template>
  <div
    v-if="appConfig.VueSettingsPreRun.EnablePersonalDataWarning && !askForConsent"
    class="v-legal-check"
  >
    <span
      class="v-mr-xxs"
      v-html="personalDataMessage"
    />
    <arora-nuxt-link
      open-in-new-tab
      :href="appConfig.VueSettingsPreRun.Links.PersonalDataLink"
      :label="translate('legalCheck.personalDataMessageLink')"
    />
  </div>
  <div
    v-if="askForConsent"
    class="v-legal-check"
  >
    <input
      id="v-check_privacy"
      class="v-input-checkbox v-label-check-privacy"
      type="checkbox"
      v-model="dataConsent"
    />
    <label
      class="v-label-check-privacy"
      for="v-check_privacy"
    >
      <span class="v-checkbox" />
      <div class="v-checkbox-text">
        <span
          class="v-mr-xxs"
          v-html="translate('legalCheck.dataConsent')"
        />
        <arora-nuxt-link
          open-in-new-tab
          :href="appConfig.VueSettingsPreRun.Links.PersonalDataLink"
          :label="translate('legalCheck.dataConsentLink')"
        />
      </div>
    </label>
  </div>
</template>

<script setup lang="ts">
const { checked, checkType } = defineProps<{
  checked: boolean
  checkType: 'account' | 'cart' | 'feedback' | 'partnership' | 'table-reservation' | 'vacancy'
}>()

const emit = defineEmits(['update:checked'])

const appConfig = useAppConfig()

const { translate } = useI18nSanitized()

const localDataConsent = ref<boolean>(false)

const dataConsent = computed<boolean>({
  get: () => {
    return localDataConsent.value
  },
  set: (value) => {
    localDataConsent.value = value
    emit('update:checked', consentRequired.value ? value : true)
  }
})

const askForConsent = computed<boolean>(() => {
  switch (checkType) {
    case 'account': {
      return appConfig.VueSettingsPreRun.AccountAskForConsent
    }
    case 'cart': {
      return appConfig.VueSettingsPreRun.PersonalInfoAskForConsent
    }
    case 'feedback': {
      return appConfig.VueSettingsPreRun.FeedbackAskForConsent
    }
    case 'partnership': {
      return appConfig.VueSettingsPreRun.PartnershipAskForConsent
    }
    case 'table-reservation': {
      return appConfig.VueSettingsPreRun.TableReservationAskForConsent
    }
    case 'vacancy': {
      return appConfig.VueSettingsPreRun.VacancyAskForConsent
    }
  }
})

const consentRequired = computed<boolean>(() => {
  switch (checkType) {
    case 'account': {
      return appConfig.VueSettingsPreRun.AccountConsentRequired
    }
    case 'cart': {
      return appConfig.VueSettingsPreRun.PersonalInfoConsentRequired
    }
    case 'feedback': {
      return appConfig.VueSettingsPreRun.FeedbackConsentRequired
    }
    case 'partnership': {
      return appConfig.VueSettingsPreRun.PartnershipConsentRequired
    }
    case 'table-reservation': {
      return appConfig.VueSettingsPreRun.TableReservationConsentRequired
    }
    case 'vacancy': {
      return appConfig.VueSettingsPreRun.VacancyConsentRequired
    }
  }
})

const personalDataMessage = computed<string>(() => {
  switch (checkType) {
    case 'cart': {
      return translate('legalCheck.personalDataMessageOrder')
    }
    case 'account':
    case 'partnership':
    case 'vacancy':
    case 'table-reservation':
    case 'feedback': {
      return translate('legalCheck.personalDataMessage')
    }
  }
})

onMounted(() => {
  localDataConsent.value = checked
  emit(
    'update:checked',
    !(
      !appConfig.VueSettingsPreRun.EnablePersonalDataWarning ||
      !askForConsent.value ||
      consentRequired.value
    )
  )
})
</script>

<style lang="scss">
@use 'assets/variables';

.v-legal-check {
  .v-link {
    text-decoration: underline;
  }
}
</style>
